
//the first three to correct a bug in Bulma version 8
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";
@import "@/scss/_bulmaOverridden";

div.genpanel {
  height: 50%;
}

